/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { GlobalSearch } from "@roambee/client-styleguide";
import { useLocation, useNavigate } from "react-router-dom";
import appRoutes from "../core/appRoute";
import styled from "styled-components";
// @ts-ignore
import { API } from "@roambee/client-utility";
import { useEffect, useState } from "react";

const Divider = styled.hr`
	border: 0;
	border-top: 1px solid #d5d4dc;
	margin: 0 24px 20px;
`;

interface Account {
	label: string;
	id: string;
}

interface User {
	account?: {
		data?: {
			title: string;
		};
		uuid?: string;
	};
}

interface TopbarProps {
	user: User;
}

const Topbar = ({ user }: TopbarProps) => {
	const { pathname } = useLocation();
	const navigate = useNavigate();

	const [account, setAccount] = useState<Account>({} as Account);
	const [accounts, setAccounts] = useState<any[]>([]);

	useEffect(() => {
		const fetchAccounts = async () => {
			if (!user) return;

			try {
				const result = await API("GET", "/accounts?quick_view=true&all=true");

				if (result.rows?.length) {
					setAccounts(result.rows);
				}

				if (user.account?.data) {
					setAccount({
						label: user.account.data.title,
						id: user.account.uuid || ""
					});
				}
			} catch (error: any) {
				if (error.status === 404) {
					console.error(error.message);
				} else {
					console.error("Error fetching accounts:", error);
				}
			}
		};

		fetchAccounts();
	}, [user]);

	const currentRoute = appRoutes.find((route) => route.path === pathname);

	const menuItems = [
		{
			lable: "Logout",
			clickHandler: async () => {
				try {
					await API("POST", "/auth/logout", {});
					navigate("/auth");
				} catch (error) {
					console.error("Logout failed:", error);
				}
			}
		}
	];

	const handleSwitchAccount = async (newValue) => {
		const targetAccount = accounts.find((acc) => acc.uuid === newValue.id);

		if (!targetAccount) {
			console.error("Account not found");
			return;
		}

		const newCustomerDetails = {
			email: targetAccount.users[0].email,
			account_uuid: targetAccount.uuid
		};

		try {
			await API("POST", "/admin/delegate", newCustomerDetails);
			window.location.reload();
		} catch (error) {
			console.error("Account switch failed:", error);
		}
	};

	return (
		<>
			<GlobalSearch
				user={user}
				showSearch={false}
				showNotification={false}
				title={currentRoute?.title || "Integration Hub"}
				menuItems={menuItems}
				accounts={accounts.map((acc) => ({
					label: acc.title,
					id: acc.uuid
				}))}
				account={account}
				handleSwitchAccount={handleSwitchAccount}
			/>
			<Divider />
		</>
	);
};

export default Topbar;
